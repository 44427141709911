import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Textarea, Alert } from 'flowbite-react';
import { Mic, Keyboard, Send, CircleStop, Stars } from 'lucide-react';
import { transcribeAudio } from './utils';
import { useLanguage } from './LanguageContext';
import { motion } from 'framer-motion';

const translations = {
  en: {
    questionOf: "Question",
    of: "of",
    startRecording: "Record your answer",
    stopRecording: "Stop Recording",
    transcriptionPlaceholder: "Transcription will appear here...",
    typingPlaceholder: "Type your answer here...",
    submitAnswer: "Submit Answer",
    changeToTyping: "Change to typing mode",
    changeToVoice: "Change to voice mode",
    errorNoAnswer: "Please provide an answer before submitting.",
    errorMicrophone: "Could not access microphone. Check your browser settings.",
    errorTranscription: "Failed to transcribe audio. Please try again.",
    recordingLimit: "Recording limit reached",
    transcribing: "Transcribing...",
    rephraseQuestion: "Too hard? Rephrase the question",
  },
  fi: {
    questionOf: "Kysymys",
    of: "/",
    startRecording: "Äänitä vastaus",
    stopRecording: "Lopeta äänitys",
    transcriptionPlaceholder: "Litterointi ilmestyy tähän...",
    typingPlaceholder: "Kirjoita vastauksesi tähän...",
    submitAnswer: "Lähetä vastaus",
    changeToTyping: "Vaihda kirjoitustilaan",
    changeToVoice: "Vaihda äänitystilaan",
    errorNoAnswer: "Anna vastaus ennen lähettämistä.",
    errorMicrophone: "Mikrofonin käyttö ei onnistu. Tarkista selaimesi asetukset.",
    errorTranscription: "Äänen litterointi epäonnistui. Yritä uudelleen.",
    recordingLimit: "Äänityksen aikaraja saavutettu",
    transcribing: "Litteroidaan...",
    rephraseQuestion: "Liian vaikea? Muotoile kysymys uudestaan.",
  }
};

const FadingText = ({ text }) => {
  const words = text.split(' ');

  return (
    <>
      {words.map((word, index) => (
        <motion.span
          key={word + index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="inline-block mr-1"
        >
          {word}
        </motion.span>
      ))}
    </>
  );
};

const QuestionPage = ({ question, onAnswer, questionNumber, totalQuestions, onRephrase }) => {
  const [answer, setAnswer] = useState('');
  const [isVoiceInput, setIsVoiceInput] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState('');
  const [recordingTime, setRecordingTime] = useState(0);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [showRephraseButton, setShowRephraseButton] = useState(false);
  const rephraseTimerRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const MAX_RECORDING_TIME = 120;

  const { language } = useLanguage();
  const t = translations[language];

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}`;
  };

  const startTimer = () => {
    if (timerIntervalRef.current) return;
    setRecordingTime(0);  
    timerIntervalRef.current = setInterval(() => {  
      setRecordingTime((prevTime) => {
        if (prevTime >= MAX_RECORDING_TIME - 1) {
          return MAX_RECORDING_TIME;
        }
        return prevTime + 1;
      });
    }, 1000);
  };

  const stopTimer = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);  
      timerIntervalRef.current = null;  
    }
    setRecordingTime(0); 
  };

  const handleInputToggle = () => {
    setIsVoiceInput(!isVoiceInput);
    if (isRecording) stopRecording();
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;

      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
        try {
          const text = await transcribeAudio(audioBlob, language);
          setAnswer(prev => prev + (prev ? ' ' : '') + text);
          setIsTranscribing(false); 
        } catch (err) {
          setError(t.errorTranscription);
          setIsTranscribing(false); 
        }
      };

      recorder.start(1000);
      setIsRecording(true);
      setError('');
      startTimer();
    } catch (err) {
      console.error('Error starting recording:', err);
      setError(t.errorMicrophone);
    }
  };

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsTranscribing(true);  
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      stopTimer();
    }
  }, [isRecording]);

  useEffect(() => {
    return () => stopRecording();
  }, [stopRecording]);

  useEffect(() => {
    if (recordingTime >= MAX_RECORDING_TIME) {
      stopRecording();
      setError(t.recordingLimit);
    }
  }, [recordingTime, t.recordingLimit, stopRecording]);

  const handleVoiceInput = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleSubmit = () => {
    if (answer.trim()) {
      onAnswer(answer);
      setAnswer('');
    } else {
      setError(t.errorNoAnswer);
    }
  };

  useEffect(() => {
    return () => stopTimer(); 
  }, []);

  const [questionKey, setQuestionKey] = useState(0);
  useEffect(() => {
    setQuestionKey(prevKey => prevKey + 1);
  }, [question]);

  useEffect(() => {
    setShowRephraseButton(false);
    if (rephraseTimerRef.current) {
      clearTimeout(rephraseTimerRef.current);
    }
    rephraseTimerRef.current = setTimeout(() => {
      if (!answer.trim() && !isRecording) {
        setShowRephraseButton(true);
      }
    }, 15000);

    return () => {
      if (rephraseTimerRef.current) {
        clearTimeout(rephraseTimerRef.current);
      }
    };
  }, [question, answer, isRecording]);

  const handleInputChange = (e) => {
    setAnswer(e.target.value);
    setShowRephraseButton(false);
  };

  const handleRephrase = () => {
    if (onRephrase) {
      onRephrase();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [question]);

  return (
    <div className="max-w-2xl mx-auto p-2 sm:p-4">
      <div className="border rounded-3xl text-center px-4 sm:px-10 py-6 sm:py-10">
        <h2 className="text-sm text-gray-500 mb-2">
          {t.questionOf} {questionNumber} {t.of} {totalQuestions}
        </h2>
        <div className="mb-10 sm:mb-20 mt-10 sm:mt-20 text-lg sm:text-2xl">
          <FadingText key={questionKey} text={question} />
        </div>
        {showRephraseButton && (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={handleRephrase}
            className="text-sm text-gray-400 bg-transparent hover:bg-gray-100 py-1 px-2 rounded-md transition-colors duration-200 flex items-center justify-center mx-auto mb-6"
          >
            <Stars className="w-4 h-4 mr-1" />
            {t.rephraseQuestion}
          </motion.button>
        )}
        <div className="flex flex-col items-center mb-4 mt-6 sm:mt-8">
          {isVoiceInput && (
            <>
              <Button 
                color={isRecording ? "light" : (isTranscribing ? "gray" : "dark")}
                onClick={handleVoiceInput} 
                size="xl"
                pill
                className="py-1 px-4 sm:rounded-lg rounded-md mb-2"
                disabled={isTranscribing}
              >
                {isRecording ? <CircleStop className="mr-2 h-6 w-6" /> : 
                 isTranscribing ? null : <Mic className="mr-2 h-6 w-6" />}
                {isRecording ? t.stopRecording : 
                 isTranscribing ? t.transcribing : t.startRecording}
              </Button>
              <p className="text-sm text-gray-400 mb-2">
              {isRecording ? `${formatTime(recordingTime)} / ${formatTime(MAX_RECORDING_TIME)}` : ''}
              </p>
            </>
          )}
          {(answer || !isVoiceInput) && (
            <Textarea
              value={answer}
              onChange={(e) => handleInputChange(e)}
              className="w-full mb-4 text-md"
              rows={4}
              placeholder={isVoiceInput ? t.transcriptionPlaceholder : t.typingPlaceholder}
              readOnly={isRecording}
            />
          )}
        </div>
        {error && (
          <Alert color="red" className="mb-4">
            <span className="font-medium">{error}</span>
          </Alert>
        )}
        {(!isVoiceInput || (isVoiceInput && answer.trim())) && (
          <Button 
            onClick={handleSubmit} 
            className={`w-full mb-4 py-2 text-white transition-all duration-300 ${
              answer.trim() 
                ? "bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600" 
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!answer.trim()}
          >
            <Send className="mr-2 h-5 w-5" /> {t.submitAnswer}
          </Button>
        )}
      </div>
      <div className="w-full flex justify-center mt-4">
        <Button color="light" onClick={handleInputToggle} className="px-4 py-2 text-xs text-slate-500 border-none">
          {isVoiceInput ? (
            <>
              <Keyboard className="h-5 w-5 mr-2" />
              {t.changeToTyping}
            </>
          ) : (
            <>
              <Mic className="h-5 w-5 mr-2" />
              {t.changeToVoice}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default QuestionPage;