import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import UserInterviewer from './UserInterviewer';
import AdminDashboard from './AdminDashboard';
import AdminLogin from './AdminLogin';
import { Helmet } from 'react-helmet';
import { supabase } from './supabaseClient';

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>User Interview</title>
          <meta name="description" content="User interview application for gathering their feedback" />
        </Helmet>
        <Routes>
          <Route path="/" element={<UserInterviewer />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;