export const getNextQuestion = async (baseQuestion, previousQuestions, previousAnswers, language, isRephrasing = false) => {
  const response = await fetch('/generate-question', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      baseQuestion,
      previousQuestions,
      previousAnswers,
      language,
      isRephrasing
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to generate question');
  }

  const data = await response.json();
  return data.question;
};


export const recordAudio = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });

      const start = () => mediaRecorder.start(1000);

      const stop = () =>
        new Promise(resolve => {
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks);
            resolve(audioBlob);
          });
          mediaRecorder.stop();
        });

      resolve({ start, stop });
    } catch (err) {
      reject(err);
    }
  });
};

export const transcribeAudio = async (audioBlob, language) => {
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.mp3');
  formData.append('model', 'whisper-1');
  formData.append('language', language);
  formData.append('prompt', `${language === 'fi' ? 'Oura, Polar, Fitbit, Apple Health, Woop, Notion, Slack, Day One, älykello, ranneke, älysormus' : 'Oura, Polar, Fitbit, Woop, Notion, Slack, Day One, smartwatch, smartring'}`);

  const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: formData
  });

  if (!response.ok) throw new Error('Transcription failed');

  const data = await response.json();
  return data.text;
};
