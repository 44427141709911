import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextInput, Table } from 'flowbite-react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [baseQuestions, setBaseQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [surveyPassword, setSurveyPassword] = useState('');
  const navigate = useNavigate();

  const checkUser = useCallback(async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      setLoading(false);
    } else {
      navigate('/admin/login');
    }
  }, [navigate]);

  useEffect(() => {
    checkUser();
    fetchBaseQuestions();
  }, [checkUser]);

  const fetchBaseQuestions = async () => {
    const { data, error } = await supabase.from('base_questions').select('*');
    if (error) console.error('Error fetching base questions:', error);
    else setBaseQuestions(data);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error signing out:', error);
    else navigate('/admin/login');
  };

  const handleAddQuestion = async () => {
    const { error } = await supabase
      .from('base_questions')
      .insert([{ question: newQuestion }]);
    
    if (error) console.error('Error adding question:', error);
    else {
      fetchBaseQuestions();
      setNewQuestion('');
    }
  };

  const handleDeleteQuestion = async (id) => {
    const { error } = await supabase
      .from('base_questions')
      .delete()
      .eq('id', id);
    
    if (error) console.error('Error deleting question:', error);
    else fetchBaseQuestions();
  };

  const handleUpdateSurveyPassword = async () => {
    const { error } = await supabase
      .from('admin_settings')
      .update({ survey_password: surveyPassword })
      .eq('id', 1);
    
    if (error) console.error('Error updating survey password:', error);
    else alert('Survey password updated successfully');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
        <Button color="light" onClick={handleSignOut}>Sign Out</Button>
      </div>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Base Questions</h2>
        <Table>
          <Table.Head>
            <Table.HeadCell>Question</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {baseQuestions.map((q) => (
              <Table.Row key={q.id}>
                <Table.Cell>{q.question}</Table.Cell>
                <Table.Cell>
                  <Button color="red" onClick={() => handleDeleteQuestion(q.id)}>Delete</Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="mt-4 flex">
          <TextInput
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            placeholder="New base question"
            className="flex-grow mr-2"
          />
          <Button color="dark" onClick={handleAddQuestion}>Add Question</Button>
        </div>
      </div>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Update Survey Password</h2>
        <div className="flex">
          <TextInput
            value={surveyPassword}
            onChange={(e) => setSurveyPassword(e.target.value)}
            placeholder="New survey password"
            className="flex-grow mr-2"
          />
          <Button color="dark" onClick={handleUpdateSurveyPassword}>Update Password</Button>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;