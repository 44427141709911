import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import QuestionPage from './QuestionPage';
import ThankYouPage from './ThankYouPage';
import IntroPage from './IntroPage';
import { getNextQuestion } from './utils';
import { supabase } from './supabaseClient';
import { LanguageProvider, useLanguage } from './LanguageContext';
import { v4 as uuid4 } from 'uuid'; 

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; 

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 mt-4">
    <div 
      className="bg-blue-400 h-2.5 rounded-full transition-all duration-700 ease-in-out" 
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const InterviewContent = () => {
  const [sessionId] = useState(() => uuid4());
  const [baseQuestions, setBaseQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState('');
  const { language } = useLanguage();

  useEffect(() => {
    const fetchBaseQuestions = async () => {
      try {
        const { data, error } = await supabase
          .from('base_questions')
          .select('question')
          .order('id', { ascending: true });

        if (error) throw error;

        setBaseQuestions(data.map(item => item.question));
      } catch (error) {
        console.error('Error fetching base questions:', error);
        setError('Failed to load questions. Please try again later.');
      }
    };

    fetchBaseQuestions();
  }, [language]);

  const startInterview = () => {
    setCurrentQuestionIndex(0);
    generateNextQuestion(baseQuestions[0], [], []);
  };

  const generateNextQuestion = async (baseQuestion, prevQuestions, prevAnswers, retryCount = 0, isRephrasing = false) => {
    try {
      const generatedQuestion = await getNextQuestion(baseQuestion, prevQuestions, prevAnswers, language);
      setCurrentQuestion(generatedQuestion);
      if (!isRephrasing) {
        setPreviousQuestions([...prevQuestions, generatedQuestion]);
      }
      setError('');
    } catch (error) {
      console.error(`Failed to generate question (attempt ${retryCount + 1}):`, error);
      if (retryCount < MAX_RETRIES - 1) {
        setError(`Failed to generate question. Retrying...`);
        setTimeout(() => generateNextQuestion(baseQuestion, prevQuestions, prevAnswers, retryCount + 1, isRephrasing), RETRY_DELAY);
      } else {
        setError(`Failed to generate question. Please refresh the page or contact support.`);
      }
    }
  };

  const handleAnswer = async (answer) => {
    try {
      const { data, error: supabaseError } = await supabase
        .from('answers')
        .insert([
          { 
            session_id: sessionId, 
            question_number: currentQuestionIndex + 1, 
            base_question: baseQuestions[currentQuestionIndex],
            generated_question: currentQuestion,
            answer: answer,
            language: language
          }
        ]);

      if (supabaseError) throw supabaseError;
      console.log('Answer saved successfully:', data);

      const newAnswers = [...answers, { question: currentQuestion, answer }];
      setAnswers(newAnswers);

      if (currentQuestionIndex < baseQuestions.length - 1) {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        generateNextQuestion(baseQuestions[currentQuestionIndex + 1], previousQuestions, newAnswers.map(a => a.answer));
      } else {
        setIsCompleted(true);
      }
    } catch (error) {
      console.error('Error saving answer:', error);
      setError(`Error saving answer: ${error.message || 'Unknown error'}. Please try again.`);
    }
  };

  const handleRephrase = () => {
    const rephrasePrompt = `Rephrase this question: ${currentQuestion}`;
    generateNextQuestion(rephrasePrompt, previousQuestions, answers.map(a => a.answer), 0, true);
  };

  const progress = baseQuestions.length > 0 
    ? ((currentQuestionIndex + 1) / baseQuestions.length) * 100 
    : 0;

  if (isCompleted) {
    return <ThankYouPage answers={answers} />;
  }

  if (currentQuestionIndex === -1) {
    return <IntroPage onStart={startInterview} />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <ProgressBar progress={progress} />
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {isCompleted ? (
          <ThankYouPage answers={answers} />
        ) : currentQuestionIndex === -1 ? (
          <IntroPage onStart={startInterview} />
        ) : currentQuestion && (
          <QuestionPage
            question={currentQuestion}
            onAnswer={handleAnswer}
            onRephrase={handleRephrase}
            questionNumber={currentQuestionIndex + 1}
            totalQuestions={baseQuestions.length}
          />
        )}
      </div>
    </div>
  );
};

const UserInterviewer = () => {
  return (
    <LanguageProvider>
      <div className="max-w-2xl mx-auto p-2 sm:p-4 min-h-screen">
        <Helmet>
          <title>User Interview - Share Your Feedback</title>
          <meta name="description" content="Participate in our user interview and help us improve our product. Your feedback is valuable!" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
        <InterviewContent />
      </div>
    </LanguageProvider>
  );
};

export default UserInterviewer;