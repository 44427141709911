import React, { useState, useEffect } from 'react';
import { Button, TextInput, Select } from 'flowbite-react';
import { useLanguage } from './LanguageContext';
import { motion, AnimatePresence } from 'framer-motion';
import logo from './assets/appicon.png';

const translations = {
  en: {
    title: "Take Part in Building the Future",
    description: "At Minun, you are at the heart of everything we do. We're committed to creating a product that truly meets your needs, and your feedback is crucial in helping us make that happen.",
    description2: "• The survey is completely anonymous.\n• Your responses won't be shared with outside parties.\n• Answer honestly. There are no right or wrong answers.\n• You can speak or type your answers.",
    description3: "Thank you for taking a few minutes to share your experience!",
    passwordPlaceholder: "Enter survey password",
    startButton: "Let's Start",
    errorMessage: "Incorrect password. Please try again.",
    language: "Select survey language:",
    time: "Survey takes approx. 4-8 minutes.",
    continueButton: "Continue"
  },
  fi: {
    title: "Auta meitä rakentamaan tulevaisuutta!",
    description: "Yksi tärkeimmistä arvoistamme on olla ihmislähtöisiä. Olemme sitoutuneet luomaan tuotteen, joka todella vastaa tarpeitasi ja siksi kokemustesi ymmärtäminen on esisijaisen arvokasta.",
    description2: "• Kysely on anonyymi.\n• Vastauksia ei jaeta ulkopuolisille.\n• Vastaa rehellisesti. Oikeita tai vääriä vastauksia ei ole.\n• Voit vastata puhumalla tai kirjoittaen.",
    description3: "Kiitos, että jaat kokemuksesi kanssamme!",
    passwordPlaceholder: "Syötä kyselyn salasana",
    startButton: "Aloitetaan",
    errorMessage: "Väärä salasana. Yritä uudelleen.",
    language: "Valitse kyselyn kieli:",
    time: "Kyselyn kesto n. 4-8 minuuttia.",
    continueButton: "Jatka"
  }
};

const LockIcon = () => (
  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
  </svg>
);

const IntroPage = ({ onStart }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { language, setLanguage } = useLanguage();
  const [step, setStep] = useState(1);

  useEffect(() => {
    // Read password from URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const urlPassword = urlParams.get('password');
    if (urlPassword) {
      setPassword(urlPassword);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validPasswords = process.env.REACT_APP_SURVEY_PASSWORDS?.split(',') || [];
    if (validPasswords.includes(password)) {
      onStart();
    } else {
      setError(translations[language].errorMessage);
    }
  };

  const handleContinue = () => {
    setStep(2);
  };

  const t = translations[language];

  const buttonStyle = "bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 text-white py-2 px-4";

  return (
    <div className="max-w-2xl mx-auto p-2 sm:p-4 text-center items-center px-4 sm:px-10 py-6 sm:py-10">
      <img src={logo} alt="Logo" className="mx-auto mb-6 w-32 h-auto" /> {}
      <h1 className="text-2xl sm:text-3xl font-bold mb-3">{t.title}</h1>
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.div
            key="step1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p className="mb-6 sm:mb-10 text-base sm:text-lg">{t.description}</p>
            <p className="mb-2">{t.language}</p>
            <Select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="mb-6 sm:mb-10 max-w-xs mx-auto"
            >
              <option value="en">🇺🇸 English</option>
              <option value="fi">🇫🇮 Suomi</option>
            </Select>
            <div className="flex flex-col text-center items-center">
            <Button onClick={handleContinue} className={buttonStyle}>{t.continueButton}</Button>
            </div>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            key="step2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ul className="mb-10 mt-5 text-lg text-left sm:text-center list-none space-y-2">
              {t.description2.split('\n').map((item, index) => (
                <li key={index} className="flex items-start sm:items-center sm:justify-center">
                  <span className="mr-1 sm:mr-2 text-green-500 flex-shrink-0">✓</span>
                  <span className="text-left">{item.substring(2)}</span>
                </li>
              ))}
            </ul>
            <p className="mb-10 text-lg">{t.description3}</p>
            <form onSubmit={handleSubmit} className="flex flex-col items-center">
              <div className="flex items-center mb-4 mr-2 max-w-xs w-full">
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <LockIcon />
                  </div>
                  <TextInput
                    id="password"
                    type="password"
                    placeholder={t.passwordPlaceholder}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="pl-10 w-full"
                  />
                </div>
              </div>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <Button 
                type="submit"
                size="xl"
                className={buttonStyle}
              >
                {t.startButton}
              </Button>
            </form>
            <p className="mt-10 text-sm text-gray-400">
              {t.time}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default IntroPage;