import React, { useState } from 'react';
import { Button, TextInput } from 'flowbite-react';
import { MessageCircle, Mail, Send } from 'lucide-react';
import { useLanguage } from './LanguageContext';
import { motion } from 'framer-motion';
import { supabase } from './supabaseClient';

const translations = {
  en: {
    title: "That was all! 🥳",
    sharePrompt: "Help to make it even better and tell your friends:",
    shareText: "I just helped startup that wants us to get more value out of our data. Would you like to help them with a short survey?",
    telegram: "Telegram",
    whatsapp: "WhatsApp",
    email: "Email",
    contactInfo: "If you have any questions, you can reach out to us:",
    emailSubject: "Check out this interview",
    launchNotification: "Would you like to hear when we launch?",
    emailPlaceholder: "Enter your email",
    emailSubmit: "Notify me",
    emailDisclaimer: "Your email won't be matched with survey answers.",
    emailSuccess: "Thank you! We'll notify you when we launch.",
    emailError: "Error saving email. Please try again."
  },
  fi: {
    title: "Siinä kaikki! 🥳",
    sharePrompt: "Auta meitä kehittymään ja kerro kavereillesi:",
    shareText: "Autoin juuri startuppia, joka haluaa auttaa meitä saamaan enemmän arvoa datastamme. Haluaisitko auttaa heitä lyhyellä kyselyllä?",
    telegram: "Telegram",
    whatsapp: "WhatsApp",
    email: "Sähköposti",
    contactInfo: "Jos sinulla on kysyttävää, voit ottaa meihin yhteyttä:",
    emailSubject: "Tutustu tähän haastatteluun",
    launchNotification: "Haluatko kuulla, kun julkaisemme?",
    emailPlaceholder: "Syötä sähköpostiosoitteesi",
    emailSubmit: "Ilmoita minulle",
    emailDisclaimer: "Sähköpostiasi ei yhdistetä kyselyn vastauksiin.",
    emailSuccess: "Kiitos! Ilmoitamme sinulle, kun julkaisemme.",
    emailError: "Virhe sähköpostin tallennuksessa. Yritä uudelleen."
  }
};

const ThankYouPage = ({ answers }) => {
  const { language } = useLanguage();
  const t = translations[language];
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');

  const shareUrl = window.location.href;
  const shareText = t.shareText;

  const shareVia = (platform) => {
    let url;
    switch (platform) {
      case 'telegram':
        url = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText + " " + shareUrl)}`;
        break;
      case 'email':
        url = `mailto:?subject=${encodeURIComponent(t.emailSubject)}&body=${encodeURIComponent(shareText + " " + shareUrl)}`;
        break;
      default:
        console.error('Unsupported platform:', platform);
        return;
    }
    window.open(url, '_blank');
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('launch_notifications')
        .insert([{ email, language }]);

      if (error) throw error;
      setEmailSubmitted(true);
      setEmailError('');
    } catch (error) {
      console.error('Error saving email:', error);
      setEmailError(t.emailError);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center min-h-screen p-4 text-center"
    >
      <motion.h1 
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
        className="text-4xl font-bold mb-8"
      >
        {t.title}
      </motion.h1>
      <motion.div 
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="mb-8"
      >
        <h2 className="text-xl font-semibold mt-4">{t.sharePrompt}</h2>
      </motion.div>
      <div className="flex md:flex-row md:space-x-4 flex-col">
        {['telegram', 'whatsapp', 'email'].map((platform, index) => (
          <motion.div
            key={platform}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: index * 0.2 }}
            className="flex-1 mb-2 md:mb-0"
          >
            <Button color="light" onClick={() => shareVia(platform)} className="w-full">
              {platform === 'telegram' && <Send className="mr-2" />}
              {platform === 'whatsapp' && <MessageCircle className="mr-2" />}
              {platform === 'email' && <Mail className="mr-2" />}
              <span className="text-black">{t[platform]}</span>
            </Button>
          </motion.div>
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="mt-8 w-full max-w-md"
      >
        <h3 className="text-lg font-semibold mb-2">{t.launchNotification}</h3>
        {!emailSubmitted ? (
          <form onSubmit={handleEmailSubmit} className="flex flex-col items-center">
            <TextInput
              type="email"
              placeholder={t.emailPlaceholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full mb-2"
            />
            <Button type="submit" color="dark" className="w-full">
              {t.emailSubmit}
            </Button>
            {emailError && <p className="text-red-500 mt-2">{emailError}</p>}
          </form>
        ) : (
          <p className="text-green-500">{t.emailSuccess}</p>
        )}
        <p className="text-xs text-gray-500 mt-2">{t.emailDisclaimer}</p>
      </motion.div>
      <motion.p 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        className="text-sm mt-10 mb-4"
      >
        {t.contactInfo} <a href="mailto:hello@minun.ai">hello@minun.ai</a>
      </motion.p>
    </motion.div>
  );
};

export default ThankYouPage;